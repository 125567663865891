@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.not-found{
  height: 720px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background: transparent;
  border: 1px solid #EAE7DC;
  padding: 0;

  .content{
    height: 100%;
    background:linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 100% 1px no-repeat,
    linear-gradient(to right, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 100% / 100% 1px no-repeat,
    linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 0 0 / 1px 100% no-repeat,
    linear-gradient(to bottom, #000 50px, transparent 0, transparent calc(100% - 50px), #000 0) 100% 0 / 1px 100% no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
  }
  h1{
    font-weight: 700;
    font-size: 300px;
    line-height: 90%;
    color: #232120;
  }
  p{
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
    text-align: center;
    color: #666;
  }
 @include media-max($screen-notebook){
    h1{
      font-size: 150px;
    }
  }
}